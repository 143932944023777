/* css for the footer  */

.logo{
    height: 90px;
    width: 100px;
}
.footer{
    padding-top: 10px;
    font-style: Sans-serif;
    height: 235px;
    width: 100%;
}
.footer{
    background-color:rgb(7, 7, 7);
    box-shadow: 8px -2px 8px rgb(108, 107, 107);
}
.x-logo{
    font-size: 1.75em;
    color:white ;
    font-family: 'Calibiri body';
}
.footer-list{
    list-style: none;
}
.footer-list-text{
    padding-top: 2%;
    text-decoration: none;
    display: inline-block;
    position: relative;
    color:  #ffffff;
    font-size: large;
}
.footer-list-text:after{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color:#1bf104f4;
    transform-origin: center;
    transition: transform 0.25s ease-in-out;
}
.footer-list-text:hover:after{
    transform: scaleX(1);
    transform-origin: center;
}
.footer-list-text:hover{
    color: rgba(1, 216, 173, 0.966);
    transform: translateY(-2px);
}
.footer-name{
    letter-spacing: 1px;
    color:#0ebca4;
    font-weight: 800;
    font-family: 'Calibiri body';
    font-size: 1.3em;
    padding-left: 5px;
}
.footer-text{
    font-weight: 300;
    color: #ffffff;
}
.fa-brands{
    background-image: linear-gradient(180deg,#1f4073,#0ebca4 );
    background-clip: text;
    color:transparent ;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius:100% ;
}
.fa-brands:hover{
    transform: translateY(-3px);
    transition: 0.5s;
}
.fa-discord:hover{
    transform: rotateY(1turn);
    color:#7289da ;
}
.fa-whatsapp:hover{
    background-image: linear-gradient(45deg ,#128C7E,#075E54,#25D366,#34B7F1);
    background-clip: text;
    color: transparent;
    transform: rotateY(1turn);
}
.fa-github:hover{
    color: #a4a6a8;
    transform: rotateY(1turn);
}
.fa-linkedin-in:hover{
    color: rgb(24, 95, 189);
    transform: rotateY(1turn);
}
.fa-instagram:hover{
    background-image: linear-gradient(180deg,#405DE6,#5851DB,#833AB4,#C13584,#E1306C,#FD1D1D,#F56040,#FCAF45,#FFDC80);
    background-clip: text;
    color: transparent;
    transform: rotateY(1turn);
}
@media(max-width:480px){
    .fa-brands{
        font-size: 1em;
    }
}
.footerList{
    display: flex;
    flex-direction: column;
}
#footer-ending{
    color: #f4f1f1;
    display: flex;
    justify-content: first baseline;
    text-align: center;
    padding: 0;
    font-size: small;
}
/* Footer-Resposiveness */
@media(max-width:700px){
    .footer-text{
        font-size: small;
    }
    .footer-list-text{
        font-size: small;
    }
    .line-footer{
        margin-left: 12px;
    }
    .bottom-footer{
        font-size: x-small;
    }
    .footer-name{
        font-size: medium;
    }
}
.bottom-footer{
    height: 50px;
    margin-top:10px ;
}

/* CSS For Navbar */

.mainContainer{
    height: 60px;
    width: 100vw;
    display: flex;
    background-color: black;
    position: fixed;
    z-index: 1;
}
.title{
    color: #0ebca4;
    font-size: xx-large;
    position: relative;
    bottom: 15px;
    left: 115px;
    font-family: 'Calibri bold';
}
.sell{
    color: #0ebca4;
    position: relative;
    left: 115px;
    bottom: 2px;
    font-size: 25px;
    font-family: 'Calibri bold';
}
.mrX{
    color: #ffffff;
    font-size: 60px;
    position: relative;
    left: 200px;
    bottom: 98px;
    font-weight: 600;
    font-family: 'Calibri bold';
}
.logoContainer{
    align-content: center;
    position: relative;
    left: 105px;
}
.logo{
    height: 60px;
    width: 60px;
    padding-top: 5px;
}
.button{
    text-align: center;
    background-color: black;
    border: 2px solid grey;
    border-radius: 20px;
    height: 40px;
    width: 95px;
    color: #ffffff;
    margin-left: 70%;
    margin-top:12px;
    font-size: larger;
}
@media (hover:hover)
{
    .button:hover{
        color:#0ebca4;
        cursor: pointer;
        background-color: #ffffff;    
    }
}

/* css for the responsiveness of the navbar  */

@media (min-width:300px) and (max-width:550px)
{
    .logoContainer{
        left:5%;
    }
    .mrX{
        left:115px;
    }
    .sell{
        left: 30px;
    }
    .title{
        left:30px;
    }
}
@media (min-width:318px) and (max-width:418px){
    .button{
        margin-left: 38%;   
    }
    }  
@media (min-width:501px) and (max-width:700px) 
{
    .button{
        margin-left: 38%;
    }
}
@media (min-width:419px) and (max-width:519px)
{
    .button{
        margin-left: 45%;
    }
}
@media (min-width:520px) and (max-width:649px)
{
    .button{
        margin-left: 55%;
    }
}
@media (min-width:650px) and (max-width:900px)
{
    .button{
        margin-left: 65%;
    }
}
@media (min-width:901px) and (max-width:1200px)
{
    .button{
        margin-left: 75%;
    }
}
@media (min-width:1201px) and (max-width:1600px)
{
    .button{
        margin-left: 80%;
    }
}
@media (min-width:1601px) and (max-width:2200px)
{
    .button{
        margin-left: 85%;
    }
}


/* Body of the page */
.body {
    background-color: black;
    height: 100vh;
    /*margin-top: 200px;*/
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.Image1Container {
    overflow: hidden;
}
.image1 {
    position: absolute;
    right: 0;
    /* make right -400px */
    bottom: 0;
    /* make bottom -660px and remove top*/
    transform: rotate(-90deg);
    height: 800px;
    width: 800px;
    clip: inset-rect(0, 400px, 0, 400px);
    vertical-align: middle;
    overflow: hidden;
    opacity: 0.2;
}
.Image2Container {
    overflow: hidden;
}
.image2 {
    opacity: 0.2;
    position: absolute;
    left: -50px;
    width: 400px;
    height: 400px;
    top: 70px;
    transform: rotate(90deg);
}
  
.body-container {
    color: white;
    border-left:2px solid white;
    padding: 20px;
    height: 325px;
    position: relative;
    right: 145px;
}

@media (min-width:550px){
    .body-container {
        align-items: center;
        margin-left: 250px;
        height: 375px;
        margin-top: 0;
    }
}
@media (max-width:549px)
{
    .body-container{
        margin-left: 165px;
        align-items: center;
        height: 425px;
    }
    .body-title {
        font-size: 3.75rem;
        font-weight: 85;
        line-height: 18px;
    }
    
    .event-name {
        font-size: 4rem;
        font-weight: 700;
        font-family: 'Calibri Bold', serif;
    }
}


.body-title {
    font-size: 4.75rem;
    font-weight: 100;
    line-height: 20px;
}

.event-name {
    font-size: 5rem;
    font-weight: 800;
    font-family: 'Calibri Bold', serif;
}
.event-name--green {
    color: #1EBAA3;
}

.event-name--white {
    color: white;
}

.body-subtitle {
    font-size: 2rem;
    font-weight: 100;
}

.date {
    font-size: 2rem;
    font-weight: 100;
}

.register-button {
    margin: 20px 15px;
    background-color: Transparent;
    color:rgb(0,97,253);
    font-weight: 100;
    letter-spacing: 5px;
    border: 1px solid rgb(0,97,253);
    padding: 8px;
    font-size: 1.5rem;
    transition: all 0.3s;
}

.register-button:hover {
    cursor: pointer;
    background-color: rgb(0,97,253);
    color: white;
}

/* Glow on hover  */
.glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
